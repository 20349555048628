// @flow

import './index.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import FacebookIcon from '_images/facebook-icon.png';
import LinkedinIcon from '_images/linkedin-icon.png';
import TwitterIcon from '_images/twitter-icon.png';

type Props = {
  article: Object,
};

const domain = process.env.GATSBY_WEB_DOMAIN;

const ShareSection = ({ article }: Props): React$Node => {
  const { t } = useTranslation();

  const url = `${domain}/article/${article.slug.current}`;

  // eslint-disable-next-line no-console
  console.log(url);

  return (
    <div className="ShareSection">
      <div className="Title">{t('Common_SHARE')}</div>
      <FacebookShareButton className="SocialIcon" url={url}>
        <img src={FacebookIcon} alt="Facebook" />
      </FacebookShareButton>
      <TwitterShareButton className="SocialIcon" url={url}>
        <img src={TwitterIcon} alt="Twitter" />
      </TwitterShareButton>
      <LinkedinShareButton className="SocialIcon" url={url}>
        <img src={LinkedinIcon} alt="LinkedIn" />
      </LinkedinShareButton>
    </div>
  );
};

export default ShareSection;
