// @flow
import './article.less';

import BlockContent from '@sanity/block-content-to-react';

import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import ArticlePreviewVertical from '_components/ArticlePreviewVertical';
import Layout from '_components/Layout';
import Seo from '_components/Seo';
import ShareSection from '_components/ShareSection';
import Tag from '_components/Tag';
// import SignUpBoxSmall from '_components/SignUpBoxSmall';
import TitleWithSeparator from '_components/TitleWithSeparator';

type Props = {
  pageContext: Object,
};

const RECENT_ARTICLES_TO_DISPLAY = 3;

type QuoteRendererProps = {
  children: Array<String>,
  isInline?: boolean,
  node: Object,
  options: Object,
};

const renderQuote = (props: QuoteRendererProps) => {
  if (props.node.style === 'blockquote') {
    return (
      <div className="quoteContainer">
        <div className="sideHighlight" />
        <blockquote>{props.children}</blockquote>
      </div>
    );
  }

  return BlockContent.defaultSerializers.types.block(props);
};

// markup
const Article = ({ pageContext }: Props): React$Node => {
  const { t } = useTranslation();

  const { article, allArticles } = pageContext;

  const recentArticles =
    allArticles.length > RECENT_ARTICLES_TO_DISPLAY ? allArticles.slice(0, RECENT_ARTICLES_TO_DISPLAY) : allArticles;

  return (
    <Layout>
      <Seo title={article.title || 'Untitled'} description={article.previewSnippet} image={article.mainImage} />
      <div className="ArticleViewWrapper">
        <article className="Article">
          {article.categories[0] && <Tag title={article.categories[0].title} />}
          <h1 className="Title">{article.title}</h1>
          <div className="PreviewSnippet">{article.previewSnippet}</div>
          <div className="HeadInfo">
            <div className="ReadTime">
              {t('Common_ReadTime')}: {article.readingTime}
            </div>
            <div className="AuthorInfo">
              <section className="LhsWrapper">
                <div className="AuthorName">{article.author?.name}</div>
                <div className="AuthorTitle">{article.author?.title}</div>
              </section>
              <div className="AuthorAvatar">
                {article.author.image && (
                  <img src={article.author.image.asset.url} alt={article.author.image.asset.title} />
                )}
              </div>
            </div>
          </div>
          <div className="ImageWrapper">
            {article.mainImage && <img src={article.mainImage.asset.url} alt={article.mainImage.asset.title} />}
          </div>
          <section className="BodyWrapper">
            <section className="SocialShare">
              <ShareSection article={article} />
            </section>
            <section className="ContentBody">
              <BlockContent
                projectId={process.env.GATSBY_SANITY_PROJECT_ID}
                dataset={process.env.GATSBY_SANITY_DATASET}
                blocks={article._rawBody}
                serializers={{ types: { block: renderQuote } }}
              />
              <TitleWithSeparator title={t('Common_Tags')} />
              <div className="TagListWrapper">
                {article.tags.map((tag) => (
                  <Tag key={tag.id} title={tag.title} />
                ))}
              </div>
              <TitleWithSeparator title={t('Common_AboutTheAuthor')} />
              <div className="AboutTheAuthorWrapper">
                <div className="AuthorAvatar">
                  {article.author.image && (
                    <img src={article.author.image.asset.url} alt={article.author.image.asset.title} />
                  )}
                </div>
                <div className="AuthorInfo">
                  <div className="AuthorName">{article.author?.name}</div>
                  <div className="AuthorBio">
                    <BlockContent blocks={article.author?._rawBio} />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </article>
        <section className="RightSideBar">
          {/* <SignUpBoxSmall /> */}
          <section className="RecentArticlesWrapper">
            <TitleWithSeparator title={t('Common_RecentArticles')} />
            {recentArticles.map((_article) => (
              <div className="ArticlePreviewItemWrapper" key={_article.node.id}>
                <ArticlePreviewVertical article={_article.node} />
              </div>
            ))}
          </section>
        </section>
      </div>
    </Layout>
  );
};

export default Article;

export const pageQuery: any = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
